import { ImageListItemBar } from '@mui/material';
import styled from 'styled-components';

export const CustomImageListItemBar = styled(ImageListItemBar)`
  height: 100%;
  display: flex;

  .MuiImageListItemBar-titleWrap {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  &:hover {
    cursor: pointer;
    background-color: #151e2680;

    .MuiImageListItemBar-titleWrap {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    };

    .MuiImageListItemBar-title {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    };
  }
`;

interface StyledTextProps {
  color?: string;
  image?: string;
}

export const ImageCart = styled.div<StyledTextProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: ${({ image }) => (image ? `url('${image}')` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 800px;
  width: 100%;
`;

export const ProductsTitle = styled.p`
  font-family: MontserratarmMedium;
  color: black;
  font-size: 20px;
  text-align: start;
  font-weight: 500;
  max-width: 1060px;

  @media (min-width: 768px) {
    font-size: 48px;
    text-align: center;
  }
`;

export const Span = styled.span`
  color: #cba046;
`;

export const ProductsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-top: 100px;
  }
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
`;
