import { ImageList, ImageListItem, Typography } from '@mui/material';
import {
  CustomImageListItemBar,
  ProductsTitle,
  ProductsWrapper,
  Span,
  ContentWrapper,
  LogoWrapper,
} from './Products.styled';
import { ITEM_DATA } from './constants';
import { ELEMENT_IDS } from '../../helpers/constants';
import { useState } from 'react';
import { TraceryLeft } from '../Icons/TraceryLeft';

const imageListItemStyles = {
  height: 'auto !important',
  width: '100%',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    zIndex: 1,
  },
};

const imageListStyles = {
  overflow: 'hidden',
  padding: '40px 20px',
  margin: 0,
};

const CardData: React.FC<{
  width?: number;
  title: string;
  icon: string;
  description: string;
  isHovered: boolean;
}> = ({ width = 0, title, icon, description, isHovered }) => {
  return (
    <>
      {width < 768 ? (
        <ContentWrapper>
          <LogoWrapper>
            <img src={icon} alt='#' style={{ height: '40px' }} />
            <Typography variant='subtitle2' color='#CBA046' sx={{ fontFamily: 'MontserratarmMedium', whiteSpace: 'normal', width: '50%' }}>
              {title}
            </Typography>
          </LogoWrapper>
          <Typography
            variant='subtitle2'
            sx={{ whiteSpace: 'normal', fontSize: '12px', padding: '0 32px', fontFamily: 'MontserratarmRegular', textAlign: 'left', lineHeight: '16px' }}
          >
            {description}
          </Typography>
        </ContentWrapper>
      ) : (
        <>
          {isHovered ? (
            <div style={{ textAlign: 'start', maxHeight: '90%' }}>
              <Typography
                variant='body2'
                color='#FFD582'
                sx={{
                  whiteSpace: 'initial',
                  fontSize: width > 1400 ? '28px' : width > 1100 ? '22px' : '16px',
                  fontWeight: 500,
                  lineHeight: width > 1400 ? '30px' : width > 1100 ? '22px' : '16px',
                  fontFamily: 'MontserratarmMedium',
                }}
              >
                {title}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  whiteSpace: 'normal',
                  padding: '0',
                  marginTop: width > 1400 ? '20px' : '10px',
                  fontSize: width > 1400 ? '20px' : width > 1100 ? '16px' : '12px',
                  lineHeight: width > 1400 ? '26px' : width > 1100 ? '16px' : '12px',
                  fontWeight: 500,
                  height: '100%',
                  fontFamily: 'MontserratarmRegular',
                }}
              >
                {description}
              </Typography>
            </div>
          ) : (
            <>
              <img
                src={icon}
                alt='#'
                style={{
                  height: width > 1024 ? '100px' : '70px',
                  display: isHovered ? 'none' : 'unset',
                }}
              />
              <Typography
                variant={width > 1024 ? 'subtitle1' : 'subtitle2'}
                sx={{ whiteSpace: 'normal', fontFamily: 'MontserratarmMedium' }}
              >
                {title}
              </Typography>
            </>
          )}
        </>
      )}
    </>
  );
};

export const Products: React.FC<{ width?: number }> = ({ width = 0 }) => {
  const [hoveredTitle, setHoveredTitle] = useState('');

  const handleMouseEnter = (title: string) => {
    setHoveredTitle(title);
  };

  const handleMouseLeave = () => {
    setHoveredTitle('');
  };

  return (
    <div style={{ position: 'relative' }}>
      <TraceryLeft
        style={{ position: 'absolute', left: width < 768 ? '-40' : '-100' }}
      />
      <ProductsWrapper>
        <ProductsTitle id={ELEMENT_IDS.PRODUCT_TITLE}>
          Bootech offers several products in <Span>C2/C4ISR</Span> domains
        </ProductsTitle>
      </ProductsWrapper>
      <ImageList
        cols={width < 768 ? 1 : 4}
        rowHeight={646}
        gap={width < 768 ? 20 : 0}
        sx={imageListStyles}
      >
        {ITEM_DATA.map(({ image, title, icon, description }) => (
          <ImageListItem key={image} sx={imageListItemStyles}>
            <img
              src={image}
              alt={title}
              loading='lazy'
              style={{ width: '100%', height: width < 768 ? '240px' : 'auto' }}
            />
            <CustomImageListItemBar
              onMouseEnter={() => handleMouseEnter(title)}
              onMouseLeave={handleMouseLeave}
              title={
                <CardData
                  width={width}
                  title={title}
                  icon={icon}
                  description={description}
                  isHovered={hoveredTitle === title}
                />
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};
