export const ITEM_DATA = [
  {
    image: 'artillery_and_missiles.png',
    title: 'C2 System for Artillery and Missiles',
    icon: 'artillery_and_missiles_logo.png',
    description:
      'Our C2 solution for artillery integrates any of the existing or prospective weapon systems in the domain. That network allows to organize data input from intelligence, including UAVs and communicate optimal and detailed firing commands to single guns or batteries.',
  },
  {
    image: 'air_defense.png',
    title: 'C2 System for Air Defense',
    icon: 'air_defense_logo.png',
    description:
      'Our C2 solution for Air Defense allows to integrate any existing sensors, soft-kill and hard-kill systems into a single network. That network can be fully controlled from a C2 centre, which receives all the data and can communicate orders back to any part of the network.',
  },
  {
    image: 'air_force.png',
    title: 'C2 System for Air Force',
    icon: 'air_force_logo.png',
    description:
      'Our C2 system for Air Force integrates actions of land forces and aviation. It allows to communicate data from various intelligence sources to command centre which assigns targets to the aircraft, dramatically decreasing reaction time compared to existing operational patterns.',
  },
  {
    image: 'intelligence.png',
    title: 'C2 system for Intelligence',
    icon: 'intelligence_logo.png',
    description:
      'Our C2 system for intelligence integrates all the data coming from different sources, such as IMINT, SIGINT and HUMINT to an interactive map. System also includes various input solution.',
  },
];
