import { useEffect, useState } from 'react';
import { ELEMENT_IDS } from '../../helpers/constants';
import { scrollToElement, scrollToTop } from '../../helpers/helper';
import { CloseIcon } from '../Icons/CloseIcon';
import { HamburgerIcon } from '../Icons/HamburgerIcon';
import { HeroHeader, Menu, MenuItem, Navbar } from './Hero.styled';

const ITEMS = [
  'About us',
  'Products',
  'Other Products',
  'Contact Us',
];

const onClickFunctions = [
  () => scrollToElement(ELEMENT_IDS.ABOUT_US),
  () => scrollToElement(ELEMENT_IDS.PRODUCT_TITLE),
  () => scrollToElement(ELEMENT_IDS.OTHER_PRODUCTS),
  () => scrollToElement(ELEMENT_IDS.FOOTER),
];

export const HeaderBar: React.FC<{ width?: number }> = ({ width = 0 }) => {
  const [showComponent, setShowComponent] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const iconSize = width < 768 ? '18px' : '24px';

  const iconsStyles = { cursor: 'pointer', width: iconSize, height: iconSize };
  const closeMenu = () => {
    setIsOpen(false);
  };

  const openeMenu = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      const targetElement = document.getElementById(
        ELEMENT_IDS.CONTENT_WRAPPER
      );
      const { top = 0 } = targetElement?.getBoundingClientRect() || { top: 0 };
      setIsScrolled(top < 120);

      if (currentScrollTop > lastScrollTop) {
        // Scrolling down
        setShowComponent(false);
        setIsOpen(false);
      } else {
        // Scrolling up
        setShowComponent(true);
      }
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <HeroHeader show={showComponent} isOpen={isOpen} isScrolled={isScrolled}>
      <img
        src='Logo.png'
        alt='#'
        onClick={scrollToTop}
        style={{ cursor: 'pointer', height: width < 768 ? '20px' : '44px' }}
      />
      <>
        <Navbar isOpen={isOpen}>
          <Menu>
            {ITEMS.map((item, index) => (
              <MenuItem
                key={item.toLowerCase()}
                onClick={() => {
                  onClickFunctions[index]();
                  setIsOpen(false);
                }}
              >
                {item}
              </MenuItem>
            ))}
          </Menu>
        </Navbar>
        {isOpen ? (
          <CloseIcon style={iconsStyles} onClick={closeMenu} />
        ) : (
          <HamburgerIcon style={iconsStyles} onClick={openeMenu} />
        )}
      </>
    </HeroHeader>
  );
};
