import { useState, useEffect } from 'react';
import { ImageList, ImageListItem } from '@mui/material';
import { ITEM_DATA } from './constants';

export const MultiMedia = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ImageList variant='quilted' cols={6} rowHeight={width < 768 ? 80 : 130} gap={width < 768 ? 12 : 20} sx={{ paddingRight: width < 768 ? 0 : '60px' }}>
      {ITEM_DATA.map(({ img, cols, rows, title }: any) => (
        <ImageListItem key={img} cols={cols || 2} rows={rows || 2}>
          <img
            src={img}
            srcSet={img}
            alt={title}
            loading='lazy'
            style={{ borderRadius: '8px' }}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};
