import { FC } from "react";

type Props = {
  style?: any;
};

export const LeftTracery: FC<Props> = ({ style }) => (
  <svg
    width="236"
    height="120"
    viewBox="0 0 236 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g opacity="0.7">
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M28.9785 226.518L-62.7345 127.629L-172.218 127.629L-259.995 32.9848V31.1845L-171.741 126.343L-62.2575 126.343L29.8134 225.617L28.9785 226.518Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M20.0338 180.607L-100.064 51.2427L-178.658 51.2427L-259.995 -36.4579V-38.2582L-178.181 49.9568L-99.5867 49.9568L20.8687 179.707L20.0338 180.607Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M-256.778 -113.997L-165.899 -16.1381L-63.5719 -16.1381L129.992 192.569L129.157 193.469L-64.049 -14.8521L-166.496 -14.8521L-258.447 -113.997H-256.778Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M-64.4065 -113.992L84.0756 46.2346L83.2408 47.1348L-66.0762 -113.992H-64.4065Z"
            fill="#4A4A4A"
          />
        </g>
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M85.1489 44.8236C85.9838 45.7237 85.9838 47.2668 85.1489 48.2956C84.3141 49.1957 82.883 49.1957 81.9289 48.2956C81.094 47.3954 81.094 45.8523 81.9289 44.8236C82.883 43.9234 84.3141 43.9234 85.1489 44.8236Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M-240.201 -94.0643V-62.4304L-161.965 22.0553L-64.0501 22.0553L8.34238 100.111L9.17722 99.2112L-63.5731 20.7694L-161.488 20.7694L-239.009 -62.9448V-94.0643H-240.201Z"
            fill="#4A4A4A"
          />
        </g>
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M10.2525 97.7923C11.0873 98.6925 11.0873 100.236 10.2525 101.264C9.41765 102.164 7.98648 102.164 7.03238 101.264C6.19754 100.364 6.19754 98.8211 7.03238 97.7923C7.98648 96.8922 9.41765 96.8922 10.2525 97.7923Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M-82.533 -114V-88.6673L19.5559 21.4084L18.7211 22.3086L-83.7256 -88.1529V-114H-82.533Z"
            fill="#4A4A4A"
          />
        </g>
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M20.6294 19.9935C21.4643 20.8937 21.4643 22.4368 20.6294 23.4655C19.7946 24.3657 18.3634 24.3657 17.4093 23.4655C16.5745 22.5654 16.5745 21.0222 17.4093 19.9935C18.3634 19.0933 19.7946 19.0933 20.6294 19.9935Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M32.4356 56.1319H3.8125V57.4179H31.9585L110.791 142.418L111.626 141.518L32.4356 56.1319Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M-45.0847 40.8376H-46.2773V79.287L67.3801 201.836V242.086H68.5727V201.322L-45.0847 78.7726V40.8376Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M-11.3289 -113.996L-11.3289 -92.1351L113.181 42.1161V69.7637H111.989V42.6305L-12.5215 -91.6207L-12.5215 -113.996H-11.3289Z"
            fill="#4A4A4A"
          />
        </g>
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M114.848 69.6349C114.848 70.9208 113.894 72.0781 112.582 72.0781C111.39 72.0781 110.316 71.0494 110.316 69.6349C110.316 68.3489 111.271 67.1916 112.582 67.1916C113.775 67.1916 114.848 68.3489 114.848 69.6349Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M16.4561 -62.1751V-60.8891L71.1977 -60.8891L187.837 64.875H232.918V63.5891L188.314 63.5891L71.6747 -62.1751L16.4561 -62.1751Z"
            fill="#4A4A4A"
          />
        </g>
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M232.797 61.7912C233.99 61.7912 235.063 62.8199 235.063 64.2345C235.063 65.5204 234.109 66.6777 232.797 66.6777C231.605 66.6777 230.531 65.649 230.531 64.2345C230.531 62.8199 231.485 61.7912 232.797 61.7912Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
    </g>
    <g opacity="0.4">
      <path
        d="M222.542 292.624L222.542 273.652L113.694 160.835L113.694 102.395L115.331 102.395L115.331 160.158L224.179 272.974L224.179 292.624L222.542 292.624Z"
        fill="#CBA046"
      />
      <path
        d="M117.623 102.566C117.623 100.872 116.314 99.3477 114.513 99.3477C112.876 99.3477 111.403 100.703 111.403 102.566C111.403 104.429 112.713 105.785 114.513 105.785C116.15 105.785 117.623 104.429 117.623 102.566Z"
        fill="#CBA046"
      />
      <path
        d="M72.9389 292.627L-77.1563 137.293L-77.1563 46.1592L-149.994 -29.221L-148.848 -30.5762L-75.5195 45.4817L-75.5195 136.616L75.2304 292.627L72.9389 292.627Z"
        fill="#CBA046"
      />
      <path
        d="M101.255 292.63L101.255 268.237L-20.0326 142.716L-20.0326 77.1606L-115.786 -21.7654L-114.64 -22.9512L-18.3958 76.483L-18.3958 142.038L102.892 267.559L102.892 292.63L101.255 292.63Z"
        fill="#CBA046"
      />
      <path
        d="M137.589 292.623L137.589 244.685L29.2321 132.546L29.2321 47.1717L-125.282 -112.736L-124.137 -113.922L30.8689 46.4941L30.8689 131.699L139.226 243.838L139.226 292.623L137.589 292.623Z"
        fill="#CBA046"
      />
      <path
        d="M189.804 239.948L191.44 239.948L191.44 176.256L137.917 120.864L137.917 82.4116L-14.961 -75.6328L-16.1067 -74.4471L136.28 83.0892L136.28 121.542L189.804 176.933L189.804 239.948Z"
        fill="#CBA046"
      />
      <path
        d="M59.6818 124.42L85.707 124.42L85.707 122.726L60.3365 122.726L30.5466 91.7266L29.2372 92.9123L59.6818 124.42Z"
        fill="#CBA046"
      />
      <path
        d="M64.9186 194.205L88.6523 194.205L88.6523 192.511L65.5733 192.511L3.3747 127.972L3.37471 46.4933L-54.4046 -13.3027L-55.5503 -12.117L1.7379 47.1709L1.73789 128.649L64.9186 194.205Z"
        fill="#CBA046"
      />
      <path
        d="M46.7471 244.01L78.1738 244.01L78.1738 242.316L47.4018 242.316L-57.8449 133.396L-57.8449 76.1406L-59.4817 76.1406L-59.4817 134.073L46.7471 244.01Z"
        fill="#CBA046"
      />
      <path
        d="M84.0656 63.7719L117.62 63.7719L117.62 62.0779L84.7203 62.0779L3.53467 -21.9414L2.3889 -20.7557L84.0656 63.7719Z"
        fill="#CBA046"
      />
      <path
        d="M234 151.867L190.297 106.639L191.607 105.453L234 149.496L234 151.867Z"
        fill="#CBA046"
      />
      <path
        d="M193.243 103.926C192.097 102.741 190.133 102.741 188.824 103.926C187.678 105.112 187.678 107.145 188.824 108.5C189.97 109.686 191.934 109.686 193.243 108.5C194.389 107.314 194.389 105.282 193.243 103.926Z"
        fill="#CBA046"
      />
      <path
        d="M157.234 83.0781L157.234 94.4275L188.169 126.443L188.169 149.819L234 197.25L234 199.621L186.533 150.497L186.533 127.121L155.597 95.1051L155.597 83.0781L157.234 83.0781Z"
        fill="#CBA046"
      />
      <path
        d="M153.306 83.2517C153.306 81.5577 154.616 80.0332 156.416 80.0332C158.053 80.0332 159.526 81.3883 159.526 83.2517C159.526 84.9456 158.217 86.4702 156.416 86.4702C154.616 86.4702 153.306 85.115 153.306 83.2517Z"
        fill="#CBA046"
      />
      <path
        d="M173.763 113.404L175.399 113.404L175.399 65.2965L115.165 2.79012L87.3392 2.79012L-12.8334 -100.879L-32.4751 -100.879L-32.4751 -99.185L-13.4881 -99.185L86.6844 4.48405L114.346 4.48406L173.763 65.974L173.763 113.404Z"
        fill="#CBA046"
      />
      <path
        d="M113.857 127.978L115.003 126.792L64.5893 74.4492L52.1495 74.4492L52.1495 76.1432L63.9345 76.1432L113.857 127.978Z"
        fill="#CBA046"
      />
      <path
        d="M52.3119 78.5131C50.5944 78.5131 49.202 77.0722 49.202 75.2946C49.202 73.5171 50.5944 72.0762 52.3119 72.0762C54.0295 72.0762 55.4219 73.5171 55.4219 75.2946C55.4219 77.0722 54.0295 78.5131 52.3119 78.5131Z"
        fill="#CBA046"
      />
      <path
        d="M131.209 49.7182L158.38 49.7182L158.38 48.0243L131.864 48.0243L102.892 18.2109L101.746 19.3967L131.209 49.7182Z"
        fill="#CBA046"
      />
      <path
        d="M100.269 21.0879C99.1234 19.9022 99.1234 17.8695 100.269 16.5143C101.415 15.3286 103.379 15.3286 104.689 16.5143C105.834 17.7001 105.834 19.7328 104.689 21.0879C103.379 22.2737 101.415 22.2737 100.269 21.0879Z"
        fill="#CBA046"
      />
      <path
        d="M40.8557 37.3429L58.5332 37.3429L58.5332 35.6489L41.5104 35.6489L5.17328 -1.78711L4.02752 -0.601362L40.8557 37.3429Z"
        fill="#CBA046"
      />
      <path
        d="M2.55529 1.09185C1.40952 -0.0939045 1.40952 -2.12663 2.55529 -3.48178C3.70105 -4.66753 5.66521 -4.66753 6.97466 -3.48178C8.12043 -2.29602 8.12043 -0.263298 6.97466 1.09185C5.66521 2.27761 3.86473 2.27761 2.55529 1.09185Z"
        fill="#CBA046"
      />
    </g>
  </svg>
);
