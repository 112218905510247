export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const scrollToElement = (id: string) => {
  const scrollToTopBtn = document.getElementById(id);
  scrollToTopBtn?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};
