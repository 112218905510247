import { FC } from 'react';

type Props = {
  style?: any;
};

export const InfoLines: FC<Props> = ({ style }) => (
  <svg
    width="356"
    height="381"
    viewBox="0 0 356 381"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g opacity="0.4">
      <path
        d="M13.8025 -148.561L147.967 19.6802L139.789 115.033L204.897 196.677L203.644 198.052L138.113 115.68L146.292 20.3268L11.5413 -148.649L13.8025 -148.561Z"
        fill="#CBA046"
      />
      <path
        d="M211.967 199.589C213.002 200.88 214.962 200.955 216.376 199.758C217.628 198.555 217.814 196.418 216.795 194.949C215.76 193.659 213.8 193.584 212.385 194.78C211.134 195.983 210.948 198.12 211.967 199.589Z"
        fill="#CBA046"
      />
      <path
        d="M-15.659 -150.184L-17.847 -124.664L90.5476 11.2771L84.6673 79.8626L170.257 187.007L169.02 188.204L82.9918 80.5092L88.8721 11.9237L-19.5225 -124.018L-17.2737 -150.247L-15.659 -150.184Z"
        fill="#CBA046"
      />
      <path
        d="M181.9 185.272C182.934 186.563 182.749 188.699 181.483 190.08C180.232 191.282 178.273 191.207 177.076 189.911C176.042 188.62 176.227 186.484 177.493 185.103C178.923 183.729 180.882 183.804 181.9 185.272Z"
        fill="#CBA046"
      />
      <path
        d="M-50.4545 -152.278L-54.7338 -102.164L41.9007 19.1904L34.2796 108.441L172.079 281.489L170.845 282.685L32.6082 109.087L40.2142 20.0135L-56.4204 -101.341L-52.0655 -152.341L-50.4545 -152.278Z"
        fill="#CBA046"
      />
      <path
        d="M186.619 279.764C187.653 281.054 187.468 283.191 186.201 284.571C184.951 285.774 182.992 285.698 181.795 284.402C180.76 283.112 180.946 280.975 182.212 279.595C183.478 278.214 185.437 278.289 186.619 279.764Z"
        fill="#CBA046"
      />
      <path
        d="M-80.3904 -153.497L-84.3936 -106.726L2.54933 2.57007L1.314 3.76658L-86.0825 -105.903L-82.0035 -153.559L-80.3904 -153.497Z"
        fill="#CBA046"
      />
      <path
        d="M8.78893 0.83504C9.82317 2.12522 9.63756 4.26177 8.3713 5.64229C7.12052 6.84476 5.16191 6.76959 3.96446 5.47315C2.93022 4.18297 3.11583 2.04642 4.38208 0.665896C5.81155 -0.708359 7.77015 -0.633184 8.78893 0.83504Z"
        fill="#CBA046"
      />
      <path
        d="M-110.252 -100.911L-111.864 -100.974L-117.554 -34.3816L-69.8034 25.57L-73.2389 65.7733L63.165 236.834L64.3991 235.638L-71.5668 65.1271L-68.1313 24.9239L-115.882 -35.0277L-110.252 -100.911Z"
        fill="#CBA046"
      />
      <path
        d="M73.9012 232.758C75.615 232.824 76.8726 234.392 76.7102 236.261C76.5477 238.13 75.0268 239.592 73.313 239.526C71.5992 239.461 70.3416 237.892 70.5041 236.023C70.6665 234.154 72.1874 232.693 73.9012 232.758Z"
        fill="#CBA046"
      />
      <path
        d="M8.17328 25.4423L-18.1705 24.4297L-18.3278 26.2341L7.3532 27.2212L34.6285 61.4011L36.0641 60.1889L8.17328 25.4423Z"
        fill="#CBA046"
      />
      <path
        d="M11.7127 -46.8898L-11.7038 -47.7936L-11.8558 -46.0213L10.9147 -45.1424L66.4915 24.7508L59.1813 109.999L110.823 174.761L112.06 173.564L60.857 109.352L68.1672 24.1042L11.7127 -46.8898Z"
        fill="#CBA046"
      />
      <path
        d="M120.431 171.844C121.465 173.134 121.279 175.271 120.013 176.651C118.762 177.854 116.803 177.778 115.606 176.482C114.572 175.192 114.758 173.055 116.024 171.675C117.29 170.294 119.249 170.369 120.431 171.844Z"
        fill="#CBA046"
      />
      <path
        d="M32.087 -97.4161L0.898779 -98.6149L0.745097 -96.8385L31.2836 -95.6647L125.85 22.5719L120.655 82.614L122.28 82.6765L127.536 21.9238L32.087 -97.4161Z"
        fill="#CBA046"
      />
      <path
        d="M130.078 82.5755C129.923 84.3566 128.477 85.9095 126.681 85.8407C125.048 85.778 123.702 84.2968 123.873 82.3375C124.028 80.5564 125.473 79.0035 127.27 79.0724C128.903 79.135 130.233 80.7944 130.078 82.5755Z"
        fill="#CBA046"
      />
      <path
        d="M-19.0165 86.4987L-52.7947 85.2049L-52.9516 86.9978L-19.8325 88.2664L54.1144 180.327L55.3776 179.116L-19.0165 86.4987Z"
        fill="#CBA046"
      />
      <path
        d="M59.9449 177.214C60.9794 178.504 60.7934 180.642 59.5263 182.023C58.2747 183.226 56.3152 183.151 55.1174 181.854C54.0829 180.563 54.2689 178.426 55.536 177.045C56.9509 175.848 58.7471 175.917 59.9449 177.214Z"
        fill="#CBA046"
      />
      <path
        d="M89.8068 169.14L88.1898 169.078L86.0413 194.08L142.429 264.746L143.668 263.548L87.7192 193.433L89.8068 169.14Z"
        fill="#CBA046"
      />
      <path
        d="M145.914 260.665C147.628 260.731 148.885 262.299 148.723 264.168C148.56 266.037 147.039 267.499 145.326 267.433C143.612 267.368 142.354 265.799 142.517 263.93C142.679 262.061 144.2 260.6 145.914 260.665Z"
        fill="#CBA046"
      />
      <path
        d="M108.736 125.996L79.6424 124.88L79.4852 126.679L107.917 127.769L191.533 231.878L221.122 233.013L221.279 231.214L192.351 230.104L108.736 125.996Z"
        fill="#CBA046"
      />
      <path
        d="M226.85 229.829C227.984 231.174 227.788 233.278 226.413 234.529C225.038 235.78 223.005 235.704 221.872 234.36C220.738 233.016 220.934 230.912 222.309 229.66C223.684 228.409 225.717 228.485 226.85 229.829Z"
        fill="#CBA046"
      />
      <path
        d="M-103.703 30.6849L-105.336 30.6223L-109.741 81.2091L-55.3638 149.24L-27.6003 150.304L62.8569 263.146L82.4547 263.898L82.6098 262.116L63.6652 261.39L-26.7919 148.548L-54.3921 147.49L-108.046 80.5592L-103.703 30.6849Z"
        fill="#CBA046"
      />
      <path
        d="M89.8617 259.631C91.4946 259.693 92.8402 261.175 92.6696 263.134C92.499 265.093 91.0686 266.468 89.2724 266.399C87.6395 266.337 86.294 264.855 86.4645 262.896C86.6196 261.115 88.0655 259.562 89.8617 259.631Z"
        fill="#CBA046"
      />
      <path
        d="M-46.3683 19.1069L-47.6271 20.3142L-1.88088 77.4902L10.5987 77.968L10.755 76.1803L-1.06778 75.7276L-46.3683 19.1069Z"
        fill="#CBA046"
      />
      <path
        d="M12.6186 73.7021C14.332 73.7678 15.5891 75.3362 15.4264 77.2053C15.2637 79.0743 13.7427 80.5362 12.0293 80.4705C10.3158 80.4048 9.05868 78.8364 9.22141 76.9674C9.38414 75.0984 10.9051 73.6365 12.6186 73.7021Z"
        fill="#CBA046"
      />
      <path
        d="M33.9036 -34.9233L16.276 -35.5985L16.1191 -33.8059L33.0877 -33.1559L70.6511 13.6092L71.914 12.3985L33.9036 -34.9233Z"
        fill="#CBA046"
      />
      <path
        d="M75.0455 10.4987C76.08 11.7894 75.894 13.9267 74.6269 15.3078C73.3753 16.5108 71.4158 16.4357 70.218 15.1388C69.1835 13.8482 69.3695 11.7108 70.6366 10.3297C72.0515 9.133 74.011 9.20813 75.0455 10.4987Z"
        fill="#CBA046"
      />
      <path
        d="M92.272 30.1823L91.0448 31.3766L105.581 49.6836L102.931 80.9847L125.608 109.544L126.835 108.349L104.594 80.3396L107.245 49.0385L92.272 30.1823Z"
        fill="#CBA046"
      />
      <path
        d="M130.695 106.616C131.73 107.906 131.544 110.042 130.278 111.423C129.027 112.625 127.068 112.55 125.871 111.253C124.836 109.963 125.022 107.827 126.288 106.446C127.717 105.072 129.676 105.147 130.695 106.616Z"
        fill="#CBA046"
      />
      <path
        d="M22.8828 116.6L5.07236 115.918L4.91538 117.712L22.0661 118.369L55.2073 159.429L56.4716 158.217L22.8828 116.6Z"
        fill="#CBA046"
      />
      <path
        d="M59.4088 156.498C60.4433 157.788 60.2573 159.926 58.9902 161.307C57.7386 162.51 55.7791 162.435 54.5813 161.138C53.5467 159.847 53.7328 157.71 54.9999 156.329C56.4147 155.132 58.211 155.201 59.4088 156.498Z"
        fill="#CBA046"
      />
      <path
        d="M97.1482 -143.857L217.886 6.82587L212.573 68.143L297.357 174.291L318.01 175.085L317.856 176.862L296.553 176.044L210.885 68.7915L216.198 7.47435L94.8715 -143.945L97.1482 -143.857Z"
        fill="#CBA046"
      />
      <path
        d="M327.847 172.586C329.48 172.649 330.826 174.13 330.656 176.089C330.501 177.87 329.055 179.423 327.258 179.354C325.625 179.292 324.28 177.81 324.45 175.851C324.605 174.07 326.214 172.523 327.847 172.586Z"
        fill="#CBA046"
      />
      <path
        d="M118.762 -54.9688L88.1475 -56.1477L87.9947 -54.3734L117.961 -53.2195L192.758 40.6274L186.934 108.228L188.553 108.29L194.439 39.9801L118.762 -54.9688Z"
        fill="#CBA046"
      />
      <path
        d="M195.775 108.182C195.62 109.963 194.175 111.516 192.378 111.447C190.745 111.384 189.399 109.903 189.57 107.944C189.725 106.163 191.171 104.61 192.967 104.679C194.763 104.748 195.946 106.223 195.775 108.182Z"
        fill="#CBA046"
      />
      <path
        d="M150.758 49.3833L149.511 50.756L174.745 82.4615L171.831 116.63L199.997 151.824L201.23 150.628L173.501 115.984L176.415 81.8156L150.758 49.3833Z"
        fill="#CBA046"
      />
      <path
        d="M205.82 148.715C206.855 150.006 206.669 152.143 205.402 153.524C204.15 154.727 202.191 154.652 200.992 153.355C199.957 152.064 200.143 149.927 201.41 148.546C202.825 147.349 204.622 147.418 205.82 148.715Z"
        fill="#CBA046"
      />
      <path
        d="M243.243 99.3727L241.674 99.3102L238.404 139.809L255.683 161.695L256.881 160.507L240.03 139.167L243.243 99.3727Z"
        fill="#CBA046"
      />
      <path
        d="M260.026 158.598C261.063 159.889 260.878 162.026 259.611 163.406C258.36 164.608 256.399 164.533 255.199 163.236C254.163 161.945 254.347 159.808 255.614 158.428C256.866 157.226 258.827 157.301 260.026 158.598Z"
        fill="#CBA046"
      />
      <path
        d="M0.410077 174.877L-1.18829 174.815L-5.23157 222.876L77.9971 327.699L74.8606 364.981L76.4589 365.044L79.6549 327.054L-3.57374 222.232L0.410077 174.877Z"
        fill="#CBA046"
      />
      <path
        d="M83.9168 364.927C83.7623 366.708 82.3164 368.261 80.5196 368.192C78.8861 368.129 77.5396 366.647 77.7096 364.688C77.8795 362.729 79.3099 361.355 81.1067 361.424C82.8881 361.671 84.0713 363.146 83.9168 364.927Z"
        fill="#CBA046"
      />
    </g>
  </svg>
);
