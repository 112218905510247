import { useEffect, useRef, useState } from 'react';
import { ELEMENT_IDS, VIDEO_FILE_NAME } from '../../helpers/constants';
import { scrollToElement } from '../../helpers/helper';
import { HeaderBar } from './HeaderBar';
import {
  HeroWrapper,
  HeroContentWrapper,
  HeroContentTitle,
  HeroContentDescription,
  HeroContentButton,
  VideoContainer,
  Video,
  Overlay,
  HeroContentButtonWrapper,
} from './Hero.styled';

export const Hero: React.FC<{ width?: number }> = ({ width = 0 }) => {
  const [videoEnded, setVideoEnded] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 2.0;
    }
  }, []);

  const handleVideoEnded = () => {
    setVideoEnded(true);
  };

  return (
    <VideoContainer>
      <Video autoPlay muted onEnded={handleVideoEnded} playsInline ref={videoRef}>
        <source src={VIDEO_FILE_NAME} type='video/mp4' />
        Your browser does not support the video tag.
      </Video>
      <Overlay>
        {videoEnded && (
          <HeroWrapper>
            <HeaderBar width={width} />
            <HeroContentWrapper>
              <HeroContentTitle>Defense System Integrator</HeroContentTitle>
              <HeroContentDescription>
                We provide modern C4ISR solutions for Armed Forces and integrate
                weapon systems of all classes with our state-of-the-art
                software.
              </HeroContentDescription>
              <HeroContentButtonWrapper>
                <HeroContentButton onClick={() => scrollToElement(ELEMENT_IDS.PRODUCT_TITLE)}>
                  OUR PRODUCTS
                </HeroContentButton>
              </HeroContentButtonWrapper>
            </HeroContentWrapper>
          </HeroWrapper>
        )}
      </Overlay>
    </VideoContainer>
  );
};
