import { FC } from "react";

type Props = {
  style?: any;
};

export const TraceryLeft: FC<Props> = ({ style }) => (
  <svg
    width="276"
    height="436"
    viewBox="0 0 276 436"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g opacity="0.2">
      <path
        d="M-9.09473 252.503H35.7439L108.566 325.324L109.711 324.179L36.3985 250.866H-9.09473V252.503Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M111.187 322.545C112.332 323.69 112.332 325.654 111.187 326.963C110.041 328.109 108.077 328.109 106.768 326.963C105.623 325.818 105.623 323.854 106.768 322.545C108.077 321.236 110.041 321.236 111.187 322.545Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M-9.09473 195.555H5.63331L101.366 291.123L102.511 289.978L6.28788 193.918H-9.09473V195.555Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M99.5662 292.594C100.712 293.74 102.675 293.74 103.985 292.594C105.13 291.448 105.13 289.485 103.985 288.176C102.839 287.03 100.875 287.03 99.5662 288.176C98.4207 289.485 98.4207 291.448 99.5662 292.594Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M-10 147.303H33.8568L133.483 251.783L134.629 250.638L34.5114 145.667H-10V147.303Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M132.206 253.924C133.351 255.07 135.315 255.07 136.624 253.924C137.77 252.779 137.77 250.815 136.624 249.506C135.479 248.36 133.515 248.36 132.206 249.506C131.06 250.815 131.06 252.779 132.206 253.924Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M-9.09277 39.2678H-0.09231L152.261 191.457L153.407 190.312L0.725914 37.6314H-9.09277V39.2678Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M150.625 192.932C151.771 194.077 153.735 194.077 155.044 192.932C156.189 191.786 156.189 189.823 155.044 188.513C153.898 187.368 151.935 187.368 150.625 188.513C149.316 189.823 149.316 191.786 150.625 192.932Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M-9.0957 173.789H34.7611L92.0368 231.064L93.1823 229.919L35.4157 172.152H-9.0957V173.789Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M90.2371 232.537C91.3826 233.683 93.3463 233.683 94.6555 232.537C95.801 231.392 95.801 229.428 94.6555 228.119C93.51 226.973 91.5462 226.973 90.2371 228.119C89.0916 229.428 89.0916 231.392 90.2371 232.537Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M-9.0957 233.192V234.828H6.77784V233.192H-9.0957Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M8.762 231.795C7.54777 230.581 5.57913 230.581 4.36491 231.795C3.15068 233.009 3.15067 234.978 4.3649 236.192C5.57912 237.406 7.5478 237.406 8.76203 236.192C9.97625 234.978 9.97623 233.009 8.762 231.795Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M101.531 171.829L100.222 172.975L18.7266 91.3161L18.7266 57.769H20.363L20.363 90.6616L101.531 171.829Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M98.5818 174.446C99.7273 175.591 101.691 175.591 103 174.446C104.146 173.3 104.146 171.336 103 170.027C101.855 168.882 99.8909 168.882 98.5818 170.027C97.4363 171.336 97.4363 173.3 98.5818 174.446Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M167.99 258.306L166.845 259.451L104.496 196.939H81.9131V195.303H105.151L167.99 258.306Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M165.206 260.924C166.351 262.07 168.315 262.07 169.624 260.924C170.77 259.779 170.77 257.815 169.624 256.506C168.479 255.36 166.515 255.36 165.206 256.506C164.06 257.815 164.06 259.779 165.206 260.924Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M141.787 336.283H140.15V307.809L48.6729 216.332L48.6729 187.367H50.3093L50.3093 215.514L141.787 306.991V336.283Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M138.024 336.285C138.024 337.922 139.334 339.395 141.134 339.395C142.77 339.395 144.243 338.085 144.243 336.285C144.243 334.649 142.934 333.176 141.134 333.176C139.334 333.176 138.024 334.485 138.024 336.285Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M-0.0919201 22.252C1.54453 22.252 3.01733 20.9428 3.01733 19.1427C3.01733 17.5063 1.70817 16.0335 -0.0919201 16.0335C-1.72837 16.0335 -3.20117 17.3426 -3.20117 19.1427C-3.20117 20.7792 -1.89201 22.252 -0.0919201 22.252Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M-9.09473 1.63626H16.5975L76.0005 61.0392V88.6951L176.151 188.845V207.828H177.788V188.191L77.637 88.0405V60.3846L17.2521 -0.000183105H-9.09473V1.63626Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M173.858 207.66C173.858 209.297 175.168 210.77 176.968 210.77C178.604 210.77 180.077 209.46 180.077 207.66C180.077 206.024 178.768 204.551 176.968 204.551C175.168 204.551 173.858 206.024 173.858 207.66Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M-9.09277 95.7327L6.78077 111.606L6.78077 123.389H8.41722V110.952L-9.09277 93.4417V95.7327Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M4.49023 123.223C4.49023 124.859 5.79939 126.332 7.59949 126.332C9.39958 126.332 10.7087 125.023 10.7087 123.223C10.7087 121.586 9.39958 120.114 7.59949 120.114C5.79939 120.114 4.49023 121.423 4.49023 123.223Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M62.7442 72.4951L61.5986 73.6406L32.1426 44.3483V17.0197H33.779V43.6937L62.7442 72.4951Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M59.9614 75.1096C61.1069 76.2551 63.0706 76.2551 64.3798 75.1096C65.5253 73.9641 65.5253 72.0004 64.3798 70.6912C63.2343 69.5457 61.2705 69.5457 59.9614 70.6912C58.6522 72.0004 58.6522 73.9641 59.9614 75.1096Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M-9.09473 212.572H5.46966L30.671 237.609L31.8165 236.464L6.12424 210.935H-9.09473V212.572Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M28.8709 239.08C30.0164 240.226 31.9801 240.226 33.2893 239.08C34.4348 237.935 34.4348 235.971 33.2893 234.662C32.1437 233.516 30.18 233.516 28.8709 234.662C27.7253 235.971 27.7253 237.771 28.8709 239.08Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M82.0539 170.19L80.9084 171.336L44.252 134.68V117.006H45.8884V134.025L82.0539 170.19Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M79.2739 172.807C80.4194 173.952 82.3831 173.952 83.6923 172.807C84.8378 171.661 84.8378 169.698 83.6923 168.389C82.5468 167.243 80.583 167.243 79.2739 168.389C77.9647 169.698 77.9647 171.661 79.2739 172.807Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M-9.0957 320.578L82.5454 412.219V432.838H84.1818V411.564L-9.0957 318.287V320.578Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M80.2559 432.674C80.2559 434.31 81.565 435.783 83.3651 435.783C85.0016 435.783 86.4744 434.474 86.4744 432.674C86.4744 431.038 85.1652 429.565 83.3651 429.565C81.565 429.728 80.2559 431.038 80.2559 432.674Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M-9.09277 298.16V299.797H27.0727V298.16H-9.09277Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M26.9081 301.926C28.5445 301.926 30.0173 300.617 30.0173 298.817C30.0173 297.18 28.7082 295.707 26.9081 295.707C25.108 295.707 23.7988 297.016 23.7988 298.817C23.9625 300.617 25.2716 301.926 26.9081 301.926Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M-9.0918 268.541L3.67249 281.306H35.0923L66.1848 312.234L67.3303 311.089L35.7469 279.669H4.32707L-9.0918 266.25V268.541Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M64.3855 313.707C65.531 314.853 67.4947 314.853 68.8039 313.707C69.9494 312.562 69.9494 310.598 68.8039 309.289C67.6584 308.143 65.6947 308.143 64.3855 309.289C63.24 310.598 63.24 312.562 64.3855 313.707Z"
        fill="#CBA046"
        fill-opacity="0.5"
      />
      <path
        d="M73.5456 363.948L72.4001 365.094L53.2537 345.947H16.4336V344.311H53.9082L73.5456 363.948Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M70.7664 366.563C71.9119 367.708 73.8756 367.708 75.1848 366.563C76.3303 365.417 76.3303 363.454 75.1848 362.144C74.0393 360.999 72.0755 360.999 70.7664 362.144C69.6208 363.454 69.6208 365.417 70.7664 366.563Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M272.54 204.061V205.697H237.356L143.424 111.929L99.5674 111.929V110.293L144.242 110.293L238.011 204.061H272.54Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
      <path
        d="M275.483 204.883C275.483 203.166 274.091 201.774 272.374 201.774C270.657 201.774 269.265 203.166 269.265 204.883C269.265 206.6 270.657 207.992 272.374 207.992C274.091 207.992 275.483 206.6 275.483 204.883Z"
        fill="#151E26"
        fill-opacity="0.5"
      />
    </g>
  </svg>
);
