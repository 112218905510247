import { FC } from "react";

type Props = {
  style?: any;
};

export const RightTracery: FC<Props> = ({ style }) => (
  <svg
    width="248"
    height="120"
    viewBox="0 0 248 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g opacity="0.7">
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M206.084 -88.5176L297.797 10.3706L407.28 10.3706L495.058 105.015V106.815L406.803 11.6565L297.32 11.6565L205.249 -87.6174L206.084 -88.5176Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M215.029 -42.6074L335.126 86.7573H413.72L495.058 174.458V176.258L413.243 88.0432H334.649L214.194 -41.7073L215.029 -42.6074Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M491.84 251.997L400.962 154.138L298.634 154.138L105.071 -54.5686L105.906 -55.4688L299.111 152.852L401.558 152.852L493.51 251.997H491.84Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M299.469 251.992L150.987 91.7654L151.822 90.8652L301.139 251.992H299.469Z"
            fill="#4A4A4A"
          />
        </g>
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M149.914 93.1764C149.079 92.2763 149.079 90.7332 149.914 89.7044C150.748 88.8043 152.18 88.8043 153.134 89.7044C153.968 90.6046 153.968 92.1477 153.134 93.1764C152.18 94.0766 150.748 94.0766 149.914 93.1764Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M475.264 232.064L475.264 200.43L397.027 115.945L299.113 115.945L226.72 37.8887L225.885 38.7888L298.636 117.231L396.55 117.231L474.071 200.945L474.071 232.064H475.264Z"
            fill="#4A4A4A"
          />
        </g>
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M224.81 40.2077C223.975 39.3075 223.975 37.7644 224.81 36.7357C225.645 35.8355 227.076 35.8355 228.03 36.7357C228.865 37.6358 228.865 39.1789 228.03 40.2077C227.076 41.1078 225.645 41.1078 224.81 40.2077Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M317.595 252V226.667L215.507 116.592L216.341 115.691L318.788 226.153V252H317.595Z"
            fill="#4A4A4A"
          />
        </g>
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M214.433 118.007C213.598 117.106 213.598 115.563 214.433 114.534C215.268 113.634 216.699 113.634 217.653 114.534C218.488 115.435 218.488 116.978 217.653 118.007C216.699 118.907 215.268 118.907 214.433 118.007Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M202.627 81.8681L231.25 81.8681V80.5821L203.104 80.5821L124.271 -4.41797L123.436 -3.51781L202.627 81.8681Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M280.147 97.1624H281.34L281.34 58.713L167.682 -63.8363V-104.086H166.49V-63.3219L280.147 59.2274L280.147 97.1624Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M246.391 251.996V230.135L121.881 95.8839V68.2363H123.074V95.3695L247.584 229.621V251.996H246.391Z"
            fill="#4A4A4A"
          />
        </g>
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M120.214 68.3651C120.214 67.0792 121.168 65.9219 122.48 65.9219C123.673 65.9219 124.746 66.9506 124.746 68.3651C124.746 69.6511 123.792 70.8084 122.48 70.8084C121.287 70.8084 120.214 69.6511 120.214 68.3651Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M285.869 191.68H287.062V168.147L240.43 117.867L239.595 118.767L285.869 168.661V191.68Z"
            fill="#4A4A4A"
          />
        </g>
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M238.521 120.188C237.686 119.288 237.686 117.745 238.521 116.716C239.356 115.816 240.787 115.816 241.741 116.716C242.576 117.616 242.576 119.159 241.741 120.188C240.787 121.088 239.356 121.088 238.521 120.188Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
      <g opacity="0.7">
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M218.606 200.175V198.889H163.865L47.2259 73.125L2.14459 73.125V74.4109L46.7488 74.4109L163.388 200.175H218.606Z"
            fill="#4A4A4A"
          />
        </g>
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M2.26523 76.2088C1.0726 76.2088 -0.000754833 75.1801 -0.000754833 73.7655C-0.000754833 72.4796 0.95334 71.3223 2.26523 71.3223C3.45786 71.3223 4.53125 72.351 4.53125 73.7655C4.53125 75.1801 3.57712 76.2088 2.26523 76.2088Z"
            fill="#4A4A4A"
          />
        </g>
      </g>
    </g>
    <g opacity="0.4">
      <path
        d="M12.5206 -154.624L12.5206 -135.652L121.368 -22.8354L121.368 35.6055L119.731 35.6055L119.731 -22.1578L10.8838 -134.974L10.8838 -154.624L12.5206 -154.624Z"
        fill="#CBA046"
      />
      <path
        d="M117.439 35.4338C117.439 37.1278 118.749 38.6523 120.549 38.6523C122.186 38.6523 123.659 37.2972 123.659 35.4338C123.659 33.5705 122.35 32.2154 120.549 32.2154C118.913 32.2154 117.439 33.5705 117.439 35.4338Z"
        fill="#CBA046"
      />
      <path
        d="M162.124 -154.627L312.219 0.706876L312.219 91.8408L385.057 167.221L383.911 168.576L310.582 92.5183L310.582 1.38446L159.832 -154.627L162.124 -154.627Z"
        fill="#CBA046"
      />
      <path
        d="M133.808 -154.63L133.808 -130.237L255.095 -4.71602L255.095 60.8394L350.848 159.765L349.703 160.951L253.458 61.517L253.458 -4.03847L132.171 -129.559L132.171 -154.63L133.808 -154.63Z"
        fill="#CBA046"
      />
      <path
        d="M97.4738 -154.623L97.4738 -106.685L205.83 5.45376L205.83 90.8283L360.345 250.736L359.199 251.922L204.194 91.5059L204.194 6.30073L95.8369 -105.838L95.837 -154.623L97.4738 -154.623Z"
        fill="#CBA046"
      />
      <path
        d="M45.2589 -101.948L43.6221 -101.948L43.6221 -38.2559L97.1457 17.1359L97.1457 55.5884L250.023 213.633L251.169 212.447L98.7825 54.9108L98.7825 16.4584L45.2589 -38.9334L45.2589 -101.948Z"
        fill="#CBA046"
      />
      <path
        d="M175.381 13.5804L149.355 13.5804L149.355 15.2744L174.726 15.2744L204.516 46.2734L205.825 45.0877L175.381 13.5804Z"
        fill="#CBA046"
      />
      <path
        d="M149.52 17.6484C147.883 17.6484 146.41 16.2933 146.41 14.43C146.41 12.5666 147.72 11.2115 149.52 11.2115C151.321 11.2115 152.63 12.5666 152.63 14.43C152.63 16.2933 151.321 17.6484 149.52 17.6484Z"
        fill="#CBA046"
      />
      <path
        d="M170.144 -56.2047L146.41 -56.2047L146.41 -54.5108L169.489 -54.5108L231.688 10.0283L231.688 91.5067L289.467 151.303L290.613 150.117L233.325 90.8291L233.325 9.35068L170.144 -56.2047Z"
        fill="#CBA046"
      />
      <path
        d="M188.315 -106.01L156.889 -106.01L156.889 -104.316L187.661 -104.316L292.907 4.60427L292.907 61.8594L294.544 61.8594L294.544 3.9267L188.315 -106.01Z"
        fill="#CBA046"
      />
      <path
        d="M150.997 74.2281L117.442 74.2281L117.442 75.9221L150.342 75.9221L231.528 159.941L232.674 158.756L150.997 74.2281Z"
        fill="#CBA046"
      />
      <path
        d="M275.879 105.392L247.071 105.392L247.071 107.086L275.224 107.086L366.722 201.777L396.021 201.777L396.021 200.083L367.377 200.083L275.879 105.392Z"
        fill="#CBA046"
      />
      <path
        d="M1.0625 -13.867L44.7653 31.3611L43.4558 32.5469L1.0625 -11.4955L1.0625 -13.867Z"
        fill="#CBA046"
      />
      <path
        d="M41.8193 34.0736C42.965 35.2593 44.9292 35.2593 46.2387 34.0736C47.3844 32.8878 47.3844 30.8551 46.2387 29.4999C45.0929 28.3142 43.1287 28.3142 41.8193 29.4999C40.6735 30.6857 40.6735 32.7184 41.8193 34.0736Z"
        fill="#CBA046"
      />
      <path
        d="M77.8288 54.9219L77.8288 43.5725L46.8931 11.5571L46.8931 -11.8193L1.06251 -59.2496L1.06251 -61.6211L48.5299 -12.4969L48.5299 10.8795L79.4656 42.8949L79.4656 54.9219L77.8288 54.9219Z"
        fill="#CBA046"
      />
      <path
        d="M81.756 54.7483C81.756 56.4423 80.4466 57.9668 78.6461 57.9668C77.0093 57.9668 75.5361 56.6117 75.5361 54.7483C75.5361 53.0544 76.8456 51.5298 78.6461 51.5298C80.4466 51.5298 81.756 52.885 81.756 54.7483Z"
        fill="#CBA046"
      />
      <path
        d="M61.2999 24.5957L59.6631 24.5957L59.6631 72.7035L119.898 135.21L147.723 135.21L247.896 238.879L267.538 238.879L267.538 237.185L248.551 237.185L148.378 133.516L120.716 133.516L61.2999 72.026L61.2999 24.5957Z"
        fill="#CBA046"
      />
      <path
        d="M121.205 10.0223L120.06 11.2081L170.473 63.5508L182.913 63.5508L182.913 61.8568L171.128 61.8568L121.205 10.0223Z"
        fill="#CBA046"
      />
      <path
        d="M182.751 59.4869C184.468 59.4869 185.86 60.9278 185.86 62.7054C185.86 64.4829 184.468 65.9238 182.751 65.9238C181.033 65.9238 179.641 64.4829 179.641 62.7054C179.641 60.9278 181.033 59.4869 182.751 59.4869Z"
        fill="#CBA046"
      />
      <path
        d="M239.711 -4.88289C240.857 -3.69714 240.857 -1.66439 239.711 -0.309239C238.566 0.876518 236.601 0.876518 235.292 -0.309239C234.146 -1.495 234.146 -3.52774 235.292 -4.88289C236.601 -6.06865 238.566 -6.06865 239.711 -4.88289Z"
        fill="#CBA046"
      />
      <path
        d="M103.854 88.2818L76.6826 88.2818L76.6826 89.9757L103.199 89.9757L132.17 119.789L133.316 118.603L103.854 88.2818Z"
        fill="#CBA046"
      />
      <path
        d="M134.793 116.912C135.939 118.098 135.939 120.131 134.793 121.486C133.648 122.671 131.683 122.671 130.374 121.486C129.228 120.3 129.228 118.267 130.374 116.912C131.683 115.726 133.648 115.726 134.793 116.912Z"
        fill="#CBA046"
      />
      <path
        d="M194.207 100.657L176.529 100.657L176.529 102.351L193.552 102.351L229.889 139.787L231.035 138.601L194.207 100.657Z"
        fill="#CBA046"
      />
      <path
        d="M243.311 -154.62L377.529 -15.8868L377.529 42.5541L471.809 140.294L492.597 140.294L492.597 141.988L471.155 141.988L375.892 43.2317L375.892 -15.2092L241.02 -154.62L243.311 -154.62Z"
        fill="#CBA046"
      />
      <path
        d="M274.902 -69.7522L243.967 -69.7522L243.967 -68.0583L274.248 -68.0583L357.725 18.502L357.725 83.041L359.362 83.041L359.362 17.8244L274.902 -69.7522Z"
        fill="#CBA046"
      />
    </g>
  </svg>
);
