import { FC } from "react";

type Props = {
  style?: any;
  onClick?: () => void
};

export const HamburgerIcon: FC<Props> = ({ style, onClick }) => (
  <svg
    width="20"
    height="13"
    viewBox="0 0 20 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    onClick={onClick}
  >
    <path
      d="M0.999023 0.103516C0.447023 0.103516 -0.000976562 0.551516 -0.000976562 1.10352C-0.000976562 1.65552 0.447023 2.10352 0.999023 2.10352H18.999C19.551 2.10352 19.999 1.65552 19.999 1.10352C19.999 0.551516 19.551 0.103516 18.999 0.103516H0.999023ZM0.999023 5.10352C0.447023 5.10352 -0.000976562 5.55152 -0.000976562 6.10352C-0.000976562 6.65552 0.447023 7.10352 0.999023 7.10352H18.999C19.551 7.10352 19.999 6.65552 19.999 6.10352C19.999 5.55152 19.551 5.10352 18.999 5.10352H0.999023ZM0.999023 10.1035C0.447023 10.1035 -0.000976562 10.5515 -0.000976562 11.1035C-0.000976562 11.6555 0.447023 12.1035 0.999023 12.1035H18.999C19.551 12.1035 19.999 11.6555 19.999 11.1035C19.999 10.5515 19.551 10.1035 18.999 10.1035H0.999023Z"
      fill="#CBA046"
    />
  </svg>
);
