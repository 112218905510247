import styled from 'styled-components';

export const Item = styled.a`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${props => props.color || '#F5F5F5'};
  margin-right: 30px;
  margin-top: 40px;
`;

export const Divider = styled.div`
  height: 4px;
  background-color: #cba046;
  width: 50%;
`;

export const InfoText = styled.h1`
  max-width: 30%;
  font-size: 20px;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

export const InfoMail = styled.h1`
  font-family: MontserratarmRegular;
  font-weight: 400;
  color: white;
  margin-left: 8px;
  font-size: 12px;

  @media (min-width: 768px) {
    margin-left: 20px;
    font-size: 22px;
  }

  @media (min-width: 1024px) {
    font-size: 30px;
  }
`;

export const MailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 36px;
  background: linear-gradient(135deg, white 24%, black 1%);
  border-bottom-right-radius: 12px;

  @media (min-width: 768px) {
    width: 70%;
    height: 70px;
  }

  @media (min-width: 1024px) {
    height: 94px;
  }
`;

export const BootomSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
