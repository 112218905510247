import styled from 'styled-components';
import { Box, Container } from '@mui/material';

export const Item = styled.a`
  font-family: MontserratarmRegular;
  font-weight: 400;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.color || '#F5F5F5'};
  margin-right: 30px;
  margin-top: 40px;
`;

export const ItemWithHover = styled(Item)`
  font-family: MontserratarmMedium;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #CBA046;
  }
`;

export const FooterWrapper = styled(Box)`
  width: 100%;
  background-color: #151E26;
  padding-bottom: 20px;
`;

interface StyledProps {
  direction?: string;
}

export const StyledContainer = styled(Container)<StyledProps>`
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => props.direction || 'row'};
`;