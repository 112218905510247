import styled from 'styled-components';

export const HeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 100%;

  @media (min-width: 768px) {
    padding: 80px;
  }
`;

export const HeroHeader = styled.div<{ show: boolean; isOpen?: boolean; isScrolled?: boolean }>`
  padding: 0 40px;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: space-between;
  color: white;
  width: calc(100% - 80px);
  height: 50px;
  background: ${(props) => (props.isOpen ? '#151e26e5' : props.isScrolled ? '#151E2680' : 'none')};
  z-index: 2;
  display: ${(props) => (props.show ? 'flex' : 'none')};

  @media (min-width: 768px) {
    width: calc(100% - 160px);
    padding: 0 80px;
    height: 105px;
  }

  @media (min-width: 1024px) {
    padding: 0 100px;
    width: calc(100% - 200px);
  }
`;

export const HeroContentWrapper = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  text-align: left;
  width: 100%;

  @media (min-width: 768px) {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
`;

export const HeroContentTitle = styled.div`
  font-family: MontserratarmMedium;
  display: flex;
  font-size: 32px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  width: 100%;

  @media (min-width: 768px) {
    justify-content: center;
    font-size: 56px;
    line-height: 68px;
    text-align: center;
  }
`;

export const HeroContentDescription = styled.div`
  font-family: MontserratarmRegular;
  display: flex;
  color: white;

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 840px;
  margin-top: 28px;

  @media (min-width: 768px) {
    justify-content: center;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    max-width: 960px;
  }
`;

export const HeroContentButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeroContentButton = styled.button`
  font-family: MontserratarmMedium;
  width: 270px;
  height: 30px;
  padding: 12px, 24px, 12px, 24px;
  border-radius: 8px;
  gap: 10px;
  border: 1px solid #cba046;
  background: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-top: 26px;

  @media (min-width: 768px) {
    height: 44px;
    padding: 14px, 24px, 14px, 24px;
    border: 2px solid #cba046;
    margin-top: 40px;
  }

  &:hover {
    border: 1px solid #cba046;
    color: #cba046;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;

  @media (min-width: 768px) {
    transform: translate(-50%, -50%);
  }
`;

export const Overlay = styled.div`
  display: flex;
  position: absolute;
  top: -4px;
  left: 0;
  color: white;
  font-size: 24px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
`;

export const Navbar = styled.nav<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 50%;
  height: calc(100vh - 50px);
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #151e26e5;
  opacity: 0.9;
  padding: 40px 20px;

  @media (min-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: unset;
    position: unset;
  }
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  align-self: start;
  height: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-left: 20px;
    justify-content: flex-end;
  }
`;

export const MenuItem = styled.li`
  margin-right: 20px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  align-self: center;
  width: calc(100% - 40px);
  text-align: left;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-weight: 500;
    width: fit-content;
    text-align: center;
    margin-bottom: 0;
  }

  &:hover {
    color: #cba046;
  }
`;
