import { Container } from '@mui/material';
import { ELEMENT_IDS } from '../../helpers/constants';
import { scrollToElement } from '../../helpers/helper';
import { Item, FooterWrapper, ItemWithHover } from './Footer.styled';

const ITEMS = ['About us', 'Products'];
const containerStyles = {
  paddingBottom: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const onClickFunctions = [
  () => scrollToElement(ELEMENT_IDS.ABOUT_US),
  () => scrollToElement(ELEMENT_IDS.PRODUCT_TITLE),
];

export const Footer: React.FC<{ width?: number }> = ({ width = 0 }) => {
  return (
    <FooterWrapper id={ELEMENT_IDS.FOOTER}>
      <Container
        maxWidth='lg'
        sx={{
          ...containerStyles,
          flexDirection: width < 768 ? 'column' : 'row',
        }}
      >
        {ITEMS.map((item, index) => (
          <ItemWithHover key={item.toLowerCase()} onClick={onClickFunctions[index]}>{item}</ItemWithHover>
        ))}
      </Container>
      <Item color='#C5C5C5'>© 2024 Bootech: System Integrator</Item>
    </FooterWrapper>
  );
};
