import { useEffect, useState } from 'react';
import { AppStyled, ContentWrapper, GlobalStyle } from './App.styled';
import { Hero } from './components/Hero/Hero';
import { NetworkCentricSection } from './components/NetworkSection/NetworkCentricSection';
import { Products } from './components/Products/Products';
import { CeoSection } from './components/Ceo/Ceo';
import { ProductItem } from './components/ProductItem/ProductItem';
import { Info } from './components/Info/Info';
import { Footer } from './components/Footer/Footer';
import { TraceryRight } from './components/Icons/TraceryRight';
import { ELEMENT_IDS } from './helpers/constants';

const traceryStyles = { position: 'absolute', right: 0, bottom: '-40' };

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AppStyled>
      <GlobalStyle/>
      <Hero width={width} />
      <ContentWrapper id={ELEMENT_IDS.CONTENT_WRAPPER}>
        <NetworkCentricSection width={width} />
        <Products width={width} />
        <CeoSection />
        <ProductItem width={width} />
        <ProductItem index={1} width={width} />
        <Info width={width} />
        <TraceryRight style={traceryStyles} />
      </ContentWrapper>
      <Footer width={width} />
    </AppStyled>
  );
}

export default App;
